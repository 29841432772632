import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class ListService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/lists`);
  }

  getLists(names: string, apiParams: any = {}, token: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/lists`;
    return this.http.get<any>(url, {
      headers: {
        'Authorization': token,
      },
      params: this.toHttpParams({ names, ...apiParams })
    });
  }

  getListsForCompany(company_id: number, names: string, apiParams: any = {}, token: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/companies/${company_id}/lists`;
    return this.http.get<any>(url, {
      headers: {
        'Authorization': token,
      },
      params: this.toHttpParams({ names, ...apiParams })
    });
  }
}
