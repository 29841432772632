import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventTokenService, EventAttendeeService, ListService } from 'app/_services';
import { BaseComponent } from 'app/_components/base.component';
import { SignatureFieldComponent } from 'app/_components/signature-field/signature-field.component';

@Component({
  selector: 'app-attendee-edit',
  templateUrl: './attendee-edit.component.html',
  styleUrls: ['./attendee-edit.component.css']
})
export class AttendeeEditComponent extends BaseComponent implements OnInit {
  @ViewChild(SignatureFieldComponent) public signatureField: SignatureFieldComponent;
  companyId: any;
  event: any;
  states: any[];
  selected = [];
  callPlan: any = {};
  attendeeSpecialities = [];
  hcpTypes = [];
  token: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventTokenService: EventTokenService,
    private eventAttendeeService: EventAttendeeService,
    private listService: ListService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if(params['id']) {
        this.companyId = params['id'];
        this.route.queryParams.subscribe((queryParams) => {
          this.token = queryParams['token'].replaceAll(' ', '+');
          this.eventTokenService.decodeToken({
            token: this.token
          }).subscribe((data) => {
            this.event = data.event;
            this.listService.getLists('CountryState,attendee_specialty', {}, data.authToken).subscribe((lists) => {
              this.states = lists.CountryState;
              this.attendeeSpecialities = lists.attendee_specialty;
            });
            this.listService.getListsForCompany(this.companyId, 'hcp_type', {}, data.authToken).subscribe((lists) => {
              this.hcpTypes = lists.hcp_type;
            });
          })
        });
      } else {
        this.navigate(this.router, '/');
      }
    });
  }
  
  onSubmit() {
    this.eventAttendeeService.create(this.callPlan, {
      company_id: this.companyId,
      event_id: this.event.id,
    }, {
      token: this.token
    }).subscribe(() => {
      this.toastr.success('Successfully Signed!');
    }, (err) => {
      this.getErrorMessage(err);
    });
  }
}
