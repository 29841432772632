<div class="container mt-3">
  <div class="mb-3">
    <p class="text-center note">
      <i>
        Please review your information below, answer the questions, sign your name in the space provided and click the 
        <b>"Update"</b>
        button when finished
      </i>
    </p>
  </div>
  <div class="mb-3">
    <form #attendeeForm="ngForm" (ngSubmit)="attendeeForm.valid && onSubmit()">
      <div class="row mb-3">
        <div class="col-sm-6">
          <div class="mb-3">
            <label class="form-label">First Name:</label>
            <input type="text" [(ngModel)]="callPlan.first_name" class="form-control" name="first_name">
          </div>
          <div class="mb-3">
            <label class="form-label">Middle Name:</label>
            <input type="text" [(ngModel)]="callPlan.middle_name" class="form-control" name="middle_name">
          </div>
          <div class="mb-3">
            <label class="form-label">Last Name:</label>
            <input type="text" [(ngModel)]="callPlan.last_name" class="form-control" name="last_name">
          </div>
          <div class="mb-3">
            <label class="form-label">Affiliation:</label>
            <input type="text" [(ngModel)]="callPlan.affiliation" class="form-control" name="affiliation">
          </div>
          <div class="mb-3">
            <label class="form-label">Address 1:</label>
            <input type="text" [(ngModel)]="callPlan.address1" class="form-control" name="address1">
          </div>
          <div class="mb-3">
            <label class="form-label">Address 2:</label>
            <input type="text" [(ngModel)]="callPlan.address2" class="form-control" name="address2">
          </div>
          <div class="mb-3">
            <label class="form-label">City:</label>
            <input type="text" [(ngModel)]="callPlan.city" class="form-control" name="city">
          </div>
          <div class="mb-3">
            <label class="form-label">State / Zip *:</label>
            <div class="row">
              <div class="col-4">
                <select name="state" class="form-control" [(ngModel)]="callPlan.state">
                  <ng-template ngFor let-state [ngForOf]="states">
                    <option [value]="state.code" *ngIf="state.country_id == 1">{{state.name}}</option>
                  </ng-template>
                </select>
              </div>
              <div class="col-8">
                <input type="text" value="{{callPlan.zip}}" class="form-control" name="zip">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb-3">
            <label class="form-label">HCP Type:</label>
            <select name="state" class="form-control" [(ngModel)]="callPlan.hcp_type">
              <ng-template ngFor let-hcpType [ngForOf]="hcpTypes">
                <option [value]="hcpType.label">{{hcpType.label}}</option>
              </ng-template>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label" for="specialties">Specialty</label>
            <ng-select [items]="attendeeSpecialities" bindLabel="value" bindValue="value" [multiple]="true" name="specialty"
              [(ngModel)]="callPlan.specialty">
            </ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label">Vendor Code:</label>
            <input type="text" [(ngModel)]="callPlan.vendor_code" class="form-control" name="vendor_code">
          </div>
          <div class="mb-3">
            <label class="form-label">NPI #:</label>
            <input type="text" [(ngModel)]="callPlan.npi_id" class="form-control" name="npi_id">
          </div>
          <div class="mb-3 row">
            <label class="form-label">License # / State:</label>
            <div class="row">
              <div class="col">
                <input type="text" [(ngModel)]="callPlan.state_license_number" class="form-control" name="state_license_number">
              </div>
              <div class="col">
                <select name="state" class="form-control" [(ngModel)]="callPlan.license_state">
                  <ng-template ngFor let-state [ngForOf]="states">
                    <option [value]="state.code" *ngIf="state.country_id == 1">{{state.name}}</option>
                  </ng-template>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Telephone:</label>
            <input type="text" [(ngModel)]="callPlan.cell_phone" class="form-control" name="cell_phone">
          </div>
          <div class="mb-3">
            <label class="form-label">RSVP Email:</label>
            <input type="text" [(ngModel)]="callPlan.email" class="form-control" name="email">
          </div>
          <div class="mb-3">
            <label class="form-label">Credentials:</label>
            <input type="text" [(ngModel)]="callPlan.degree" class="form-control" name="degree">
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p>
          <b>Have you previously attended an Organogenesis speaker program on this product?</b>
        </p>
        <div class="radio radio-info">
          <input type="radio" name="agree" id="radioYes" value="option5" checked="">
          <label for="radioYes"> Yes
          </label>
        </div>

        <div class="radio radio-info">
          <input type="radio" name="agree" id="radioNo" value="option5" checked="">
          <label for="radioNo"> No
          </label>
        </div>
      </div>
      <div class="mb-3">
        <p>
          <b>If you elect NOT to consume provided Food and Beverage: <br>Please indicate as such</b>
        </p>
        <div class="form-check form-check-inline">
          <input type="checkbox" class="form-check-input" [(ngModel)]="callPlan.meal_opt_out" name="meal_opt_out">
          <label class="form-check-label">I elect NOT to consume the provided Food or Beverage.</label>
        </div>
      </div>
      <div class="mb-3">
        <div fxLayout="row">
          <button type="button" (click)="signatureField.clear()" [disabled]="!callPlan.signature">Clear</button>
        </div>
        <div fxLayout="row">
          <app-signature-field name="signature" [(ngModel)]="callPlan.signature"></app-signature-field>
        </div>
      </div>
      <div>
          <button type="submit" class="btn btn-primary" [disabled]="!attendeeForm.form.valid">Submit</button>
      </div>
    </form>
  </div>
</div>