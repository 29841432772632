import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { SharedModule } from './_components/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { EventTokenService, CallPlanService, EventAttendeeService, ListService } from './_services';
import { AttendeeListComponent } from './attendees/attendee-list.component';
import { AttendeeEditComponent } from './attendees/attendee-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    AttendeeListComponent,
    AttendeeEditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,

    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
    }),
  ],
  providers: [
    EventTokenService,
    CallPlanService,
    EventAttendeeService,
    ListService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
