<div class="container mt-3">
  <div class="mb-3">
    <p class="text-center note">
      <i>
        Please enter your last name then click on your profile. if you do not see your profile, enter your name and state and click on 
        <b>Search</b>
      </i>
    </p>
    <h5 id="welcome" class="text-center">
      Welcome to {{event && event.common_event.name}} presented by {{ event && event.speakers[0] | fullName }} on {{ event && event.common_event.start_at | date: 'EEEE, MMMM d, y' }}
    </h5>
  </div>
  <div class="mb-3">
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
      <div class="row">
        <div class="col-3">
          <label class="form-label" for="first_name">First Name</label>
          <input type="text" formControlName="first_name" class="form-control">
        </div>
        <div class="col-3">
          <label class="form-label" for="last_name">Last Name</label>
          <input type="text" formControlName="last_name" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label" for="city">City</label>
          <input type="text" formControlName="city" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label" for="state">State</label>
          <select formControlName="state" class="form-control">
            <ng-template ngFor let-state [ngForOf]="states">
              <option [value]="state.code" *ngIf="state.country_id == 1">{{state.name}}</option>
            </ng-template>
          </select>
        </div>
        <div class="col-2">
          <label class="form-label">&nbsp;</label><br />
          <button class="btn btn-primary">Search</button>
        </div>
      </div>
    </form>
    
    <table class="table table-striped" *ngIf="callPlans">
      <thead>
        <tr>
          <th style="text-align: center; width: 150px;">Add Attendees</th>
          <th style="text-align: center;">First Name</th>
          <th style="text-align: center;">Last Name</th>
          <th style="text-align: center;">Degree</th>
          <th style="text-align: center;">City</th>
          <th style="text-align: center;">State</th>
        </tr>
      </thead>
      <tbody *ngIf="callPlans.length">
        <tr *ngFor="let callPlan of callPlans">
          <td style="text-align: center;">
            <input type="checkbox" [checked]="isChecked(callPlan)" (change)="toggleCheck(callPlan)">
          </td>
          <td style="text-align: center;" [ngClass]="{'text-bold': callPlan.current}">{{callPlan.first_name}}</td>
          <td style="text-align: center;" [ngClass]="{'text-bold': callPlan.current}">{{callPlan.last_name}}</td>
          <td style="text-align: center;" [ngClass]="{'text-bold': callPlan.current}">{{callPlan.degree}}</td>
          <td style="text-align: center;" [ngClass]="{'text-bold': callPlan.current}">{{callPlan.city}}</td>
          <td style="text-align: center;" [ngClass]="{'text-bold': callPlan.current}">{{callPlan.state}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="callPlans.length === 0">
        <tr>
          <td colspan="6" align="center">No Results!</td>
        </tr>
      </tbody>
      <tfoot *ngIf="callPlans.length">
        <tr>
          <td colspan="6" align="center">
            <button type="button" class="btn btn-primary" (click)="submitSelected()">Add Attendees</button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div class="mb-3" *ngIf="!callPlans || callPlans.length === 0">
    <h4>Attendee Not Found. And New Attendee</h4>
    <button type="button" class="btn btn-primary" (click)="addClick()">Add Attendee</button>
  </div>
</div>