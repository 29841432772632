import { Base } from './_base.model';

export class AttendeeLicense extends Base {
  id: number;
  attendee_id: number;
  license: string;
  state: string;
  expired_at: Date;
  status: string;
}
