import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { DatatablesUtils } from '../_utils/datatables';
import { CallPlan } from '../_models';

export class CallPlans {
  call_plans: CallPlan[];
  extended: any;
  total: number;
}

@Injectable()
export class CallPlanService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/call_plans`);
  }

  getCallPlans(urlParams: any, params: any, dtParams: any): Observable<CallPlans> {
    const url = sprintf(this.baseUrl, urlParams);
    const apiParams = DatatablesUtils.convertToApiParams(dtParams);
    return this.http.get<CallPlans>(url, {
      params: this.toHttpParams({
        ...params,
        ...apiParams,
      })
    });
  }
}
