import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendeeListComponent } from './attendees/attendee-list.component';
import { AttendeeEditComponent } from './attendees/attendee-edit.component';
import { DummyComponent } from './_components/dummy.component';

const routes: Routes = [
  {
    path: 'companies/:id/attendees',
    children: [
      { path: '', component: AttendeeListComponent },
      { path: 'new', component: AttendeeEditComponent },
    ]
  },
  { path: 'dummy', component: DummyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
