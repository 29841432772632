import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'app/_components/base.component';
import { EventTokenService, CallPlanService, EventAttendeeService, ListService } from 'app/_services';
import { Attendee } from 'app/_models';

@Component({
  selector: 'app-attendee-list',
  templateUrl: './attendee-list.component.html',
  styleUrls: ['./attendee-list.component.css'],
})
export class AttendeeListComponent extends BaseComponent implements OnInit {
  searchForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
  });

  companyId: string;
  event: any;
  states: any[];
  callPlans: any[];
  selected = [];
  token: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventTokenService: EventTokenService,
    private callPlanService: CallPlanService,
    private eventAttendeeService: EventAttendeeService,
    private listService: ListService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if(params['id']) {
        this.companyId = params['id'];
        this.route.queryParams.subscribe((queryParams) => {
          this.token = queryParams['token'].replaceAll(' ', '+');
          this.eventTokenService.decodeToken({
            token: this.token
          }).subscribe((data) => {
            this.event = data.event;
            this.listService.getLists('CountryState', {}, data.authToken).subscribe((lists) => {
              this.states = lists.CountryState;
            });
          })
        });
      } else {
        this.navigate(this.router, '/');
      }
    });
  }

  onSearch() {
    this.callPlanService.getCallPlans({
      company_id: this.companyId,
    }, {
      ...this.searchForm.value,
      limit: 100,
      token: this.token
    }, null).subscribe(res => {
      this.callPlans = res.call_plans;
      this.selected = [];
    });
  }

  toggleCheck(callPlan) {
    if(!this.isChecked(callPlan))  this.selected.push(callPlan);
    else {
      this.selected.splice(this.findIndex(callPlan), 1);
    }
  }

  findIndex(callPlan) {
    return this.selected.findIndex((item) => item.id === callPlan.id);
  }

  isChecked(callPlan) {
    return this.findIndex(callPlan) !== -1;
  }

  submitSelected() {
    this.selected.map(item => {
      const attendee = new Attendee().fromCallPlan(item);
      this.eventAttendeeService.create(attendee, {
        company_id: this.companyId,
        event_id: this.event.id,
      }, {
        token: this.token
      }).subscribe(() => {
        this.toastr.success('Successfully Signed!');
      }, (err) => {
        this.toastr.error(this.getErrorMessage(err));
      });
    })
  }

  addClick() {
    this.navigate(this.router, `/companies/${this.companyId}/attendees/new`, { queryParams: { token: this.token } });
  }
}
