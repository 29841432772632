import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SignaturePadModule } from 'angular2-signaturepad';
import { DataTablesModule } from 'angular-datatables';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { TitlizePipe } from 'app/_pipes/titlize.pipe';
import { FullNamePipe } from 'app/_pipes/full-name.pipe';
import { NameCharsPipe } from 'app/_pipes/name-chars.pipe';

import { SignatureFieldComponent } from './signature-field/signature-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    SignaturePadModule,

    DataTablesModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
  ],
  declarations: [
    // Pipes
    TitlizePipe,
    FullNamePipe,
    NameCharsPipe,

    // Common Component
    SignatureFieldComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    SignaturePadModule,

    DataTablesModule,
    BsDatepickerModule,
    BsDropdownModule,
    AccordionModule,

    // Pipes
    TitlizePipe,
    FullNamePipe,
    NameCharsPipe,

    // Common Components
    SignatureFieldComponent,
  ],
  entryComponents: []
})

export class SharedModule { }

