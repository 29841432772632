import { Component, ViewChild, forwardRef, AfterViewInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-signature-field',
  templateUrl: 'signature-field.component.html',
  styleUrls: ['./signature-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true,
    },
  ],
})
export class SignatureFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;
  @Input() minWidth = 2;
  @Input() canvasWidth = 400;
  @Input() canvasHeight = 200;

  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    this.propagateChange(this.signature);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    if (value.startsWith('http')) {
      fetch(value).then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.signature = reader.result;
            this.signaturePad.fromDataURL(this.signature);
          };
          reader.readAsDataURL(blob);
        });
    } else {
      this._signature = value;
      this.signaturePad.fromDataURL(this.signature);
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  public ngAfterViewInit(): void {
    this.signaturePad.clear();
  }

  public drawBegin(): void {
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }
}
